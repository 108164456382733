<template>
  <div class="p-4">
    <div class="flex gap-2 items-center mb-2 fs-18">
      <span
        class="text-black font-bold fs-18 cursor-pointer"
        @click="handleBackToList"
      >{{ $t('lbl_list_circle') }}</span>
      /
      <span
        @click="handleBackToDetail"
        class="text-black font-bold fs-18 cursor-pointer"
      >{{ $t('lbl_circle_information') }}</span>
      /
      <span class="fs-16">{{ $t('circle_feature.lbl_circle_multi_lang_meet') }}</span>
    </div>

    <div
      v-if="circleData&&circleData.one_hundred_ms_conf_id"
      class="bg-white p-4 rounded-lg container mx-auto"
    >
      <div class="flex flex-col gap-2 md:flex-row justify-between items-start">
        <div
          class="fs-16 text-black mb-4 flex-1 break-words"
        >{{ $t('lbl_with_multilingualism_as_the_foundation__our_conference_room_become_a_crucial_meeting_point_in_the_healthcare_community__where_experts_and_participants_can_exchange_opinions__share_information__and_foster_a_comprehensive_understanding_of_important_global_health_issues_') }}</div>

        <div
          v-if="(isMember || isAdmin)  && circleData  && circleData.one_hundred_ms_conf_id"
          class="open-chat-att"
          @click="goToMultiLangConference"
        >
          <div class="flex items-center">
            <div class="w-9 h-9 mx-2">
              <svg viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_123_32)">
                  <path
                    d="M118.945 103.132L77.7974 1.53337C76.9659 0.561005 75.7507 0 74.4715 0H15.2866C6.8651 0 0 6.87083 0 15.3178V234.682C0 243.129 6.8651 250 15.2866 250H80.7822C83.1914 250 85.1528 248.039 85.1528 245.624V229.911C85.1528 224.648 86.6026 219.509 89.3422 215.037L93.3824 203.401H82.6051C72.1689 203.401 62.9266 196.727 59.6219 186.795C57.3087 179.818 58.2361 176.28 62.1804 170.077C66.1139 163.88 72.5526 163.863 79.8441 163.025L98.0623 160.925L85.6433 125.759C84.9184 123.996 84.993 122.003 85.8458 120.299C86.7092 118.594 88.2657 117.349 90.1098 116.885L116.685 110.222C118.817 109.688 119.564 108.005 119.788 107.305C120.011 106.61 120.374 104.805 118.945 103.132Z"
                    fill="#20419B"
                  />
                  <path
                    d="M115.854 151.285C113.682 149.113 110.162 149.113 107.99 151.285C105.827 153.457 105.827 156.977 107.999 159.149C113.944 165.099 117.229 173.013 117.229 181.429C117.229 189.84 113.944 197.754 107.999 203.704C105.827 205.876 105.827 209.396 107.99 211.568C109.076 212.654 110.505 213.197 111.926 213.197C113.347 213.197 114.768 212.654 115.854 211.568C123.908 203.518 128.342 192.813 128.342 181.429C128.342 170.04 123.908 159.334 115.854 151.285Z"
                    fill="#20419B"
                  />
                  <path
                    d="M135.509 131.629C133.338 129.457 129.817 129.457 127.645 131.629C125.483 133.801 125.483 137.321 127.654 139.493C138.858 150.692 145.029 165.587 145.029 181.428C145.029 197.269 138.858 212.16 127.654 223.363C125.483 225.535 125.483 229.056 127.654 231.228C128.731 232.313 130.161 232.856 131.582 232.856C133.003 232.856 134.423 232.313 135.509 231.228C148.821 217.925 156.142 200.238 156.142 181.428C156.142 162.614 148.821 144.932 135.509 131.629Z"
                    fill="#20419B"
                  />
                  <path
                    d="M227.726 140.479H215.062V137.313C215.062 136.473 214.728 135.668 214.135 135.074C213.541 134.481 212.736 134.147 211.896 134.147C211.056 134.147 210.251 134.481 209.657 135.074C209.064 135.668 208.73 136.473 208.73 137.313V140.479H196.066C195.226 140.479 194.421 140.813 193.827 141.406C193.233 142 192.9 142.805 192.9 143.645C192.9 144.485 193.233 145.29 193.827 145.884C194.421 146.477 195.226 146.811 196.066 146.811H217.247C216.079 150.156 214.379 153.291 212.213 156.094C210.749 154.631 209.402 153.056 208.185 151.383C207.717 150.689 206.993 150.21 206.172 150.049C205.351 149.887 204.499 150.058 203.803 150.523C203.108 150.988 202.624 151.709 202.459 152.529C202.293 153.35 202.459 154.202 202.92 154.9C204.378 156.955 206.01 158.88 207.799 160.653C204.969 163.011 201.72 164.815 198.222 165.969C197.424 166.234 196.765 166.805 196.388 167.557C196.012 168.309 195.949 169.179 196.215 169.977C196.48 170.775 197.051 171.434 197.803 171.811C198.555 172.187 199.425 172.249 200.223 171.984C204.76 170.498 208.951 168.112 212.545 164.968C215.818 167.632 219.373 169.931 223.145 171.823C223.896 172.198 224.766 172.259 225.563 171.993C225.958 171.862 226.322 171.654 226.636 171.381C226.951 171.108 227.208 170.776 227.394 170.404C227.58 170.032 227.69 169.627 227.72 169.212C227.749 168.797 227.696 168.381 227.564 167.986C227.433 167.592 227.225 167.227 226.952 166.913C226.679 166.599 226.347 166.341 225.975 166.155C222.776 164.541 219.755 162.595 216.962 160.349C220.12 156.325 222.47 151.728 223.883 146.811H227.726C228.566 146.811 229.371 146.477 229.965 145.884C230.559 145.29 230.892 144.485 230.892 143.645C230.892 142.805 230.559 142 229.965 141.406C229.371 140.813 228.566 140.479 227.726 140.479Z"
                    fill="#20419B"
                  />
                  <path
                    d="M240.39 121.483H183.401C180.882 121.483 178.466 122.484 176.685 124.265C174.904 126.046 173.903 128.462 173.903 130.981V175.305C173.903 177.824 174.904 180.24 176.685 182.021C178.466 183.803 180.882 184.803 183.401 184.803H186.567V197.467C186.567 198.033 186.719 198.587 187.006 199.074C187.292 199.561 187.704 199.963 188.199 200.237C188.693 200.511 189.252 200.647 189.817 200.632C190.381 200.618 190.932 200.452 191.411 200.152L215.97 184.803H240.39C242.909 184.803 245.325 183.803 247.106 182.021C248.887 180.24 249.888 177.824 249.888 175.305V130.981C249.888 128.462 248.887 126.046 247.106 124.265C245.325 122.484 242.909 121.483 240.39 121.483ZM243.556 175.305C243.556 176.145 243.222 176.95 242.628 177.544C242.035 178.138 241.229 178.471 240.39 178.471H215.062C214.468 178.471 213.887 178.638 213.384 178.953L192.899 191.769V181.637C192.899 180.798 192.566 179.992 191.972 179.399C191.378 178.805 190.573 178.471 189.733 178.471H183.401C182.562 178.471 181.756 178.138 181.163 177.544C180.569 176.95 180.235 176.145 180.235 175.305V130.981C180.235 130.141 180.569 129.336 181.163 128.742C181.756 128.149 182.562 127.815 183.401 127.815H240.39C241.229 127.815 242.035 128.149 242.628 128.742C243.222 129.336 243.556 130.141 243.556 130.981V175.305Z"
                    fill="#20419B"
                  />
                  <path
                    d="M146.498 41.9097H203.487C206.006 41.9097 208.422 42.9104 210.203 44.6916C211.984 46.4728 212.985 48.8887 212.985 51.4077V95.732C212.985 98.2511 211.984 100.667 210.203 102.448C208.422 104.229 206.006 105.23 203.487 105.23H179.067L154.508 120.579C154.029 120.879 153.479 121.044 152.914 121.059C152.349 121.074 151.79 120.937 151.296 120.663C150.801 120.389 150.389 119.988 150.103 119.501C149.816 119.014 149.664 118.459 149.664 117.894V105.23H146.498C143.979 105.23 141.564 104.229 139.782 102.448C138.001 100.667 137 98.2511 137 95.732V51.4077C137 48.8887 138.001 46.4728 139.782 44.6916C141.564 42.9104 143.979 41.9097 146.498 41.9097ZM143.332 95.732C143.332 96.5717 143.666 97.377 144.26 97.9708C144.853 98.5645 145.659 98.8981 146.498 98.8981H152.83C153.67 98.8981 154.475 99.2316 155.069 99.8254C155.663 100.419 155.996 101.224 155.996 102.064V112.195L176.481 99.392C176.982 99.073 177.564 98.9018 178.159 98.8981H203.487C204.326 98.8981 205.132 98.5645 205.726 97.9708C206.319 97.377 206.653 96.5717 206.653 95.732V51.4077C206.653 50.5681 206.319 49.7628 205.726 49.169C205.132 48.5753 204.326 48.2417 203.487 48.2417H146.498C145.659 48.2417 144.853 48.5753 144.26 49.169C143.666 49.7628 143.332 50.5681 143.332 51.4077V95.732Z"
                    fill="#20419B"
                  />
                  <path
                    d="M177.841 56.3247C177.548 55.834 177.133 55.4277 176.637 55.1456C176.14 54.8634 175.578 54.7151 175.007 54.7151C174.436 54.7151 173.875 54.8634 173.378 55.1456C172.881 55.4277 172.466 55.834 172.174 56.3247L156.344 87.9849C156.158 88.357 156.047 88.7621 156.018 89.177C155.988 89.5919 156.041 90.0085 156.173 90.403C156.304 90.7976 156.513 91.1623 156.785 91.4764C157.058 91.7905 157.39 92.0478 157.762 92.2337C158.134 92.4195 158.539 92.5303 158.954 92.5596C159.369 92.5889 159.786 92.5362 160.18 92.4044C160.575 92.2727 160.939 92.0646 161.253 91.7919C161.568 91.5193 161.825 91.1874 162.011 90.8153L174.991 64.8191L180.95 76.736H174.991C174.152 76.736 173.346 77.0696 172.753 77.6633C172.159 78.2571 171.825 79.0623 171.825 79.902C171.825 80.7417 172.159 81.547 172.753 82.1407C173.346 82.7345 174.152 83.068 174.991 83.068H184.116L187.988 90.8153C188.174 91.1874 188.431 91.5193 188.745 91.7919C189.059 92.0646 189.424 92.2727 189.819 92.4044C190.213 92.5362 190.63 92.5889 191.045 92.5596C191.46 92.5303 191.865 92.4195 192.237 92.2337C192.609 92.0478 192.941 91.7905 193.213 91.4764C193.486 91.1623 193.694 90.7976 193.826 90.403C193.958 90.0085 194.01 89.5919 193.981 89.177C193.952 88.7621 193.841 88.357 193.655 87.9849L177.841 56.3247Z"
                    fill="#20419B"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_123_32">
                    <rect width="250" height="250" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div>
              <span
                class="block txt-black fs-16 fw-500"
              >{{ $t('circle_feature.lbl_go_to_conference') }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- <CircleSchedule
        v-if="circleData"
        class="col-12"
        :isAdmin="isAdmin"
        :seedMember="circleData && circleData.seed"
      />-->
      <CircleConferenceMembers :circleData="circleData" :isAdmin="isAdmin" />
    </div>
    <div v-else class="p-4 flex flex-col gap-2 items-center justify-center">
      <div
        v-if="isAdmin"
        class="fs-18 text-black text-center"
      >{{ $t('lbl_your_circle_has_no_conference_room_please_press_create_to_initiate_a_room_') }}</div>

      <div v-else class="fs-18 text-black">{{ $t('lbl_your_circle_has_no_conference_room') }}</div>
      <button
        v-if="isAdmin"
        @click="createConferenceRoom"
        class="btn bg-blue-900 hover:bg-blue-900 text-white"
      >
        <span class="mr-2 fs-24">+</span>
        {{ $t('circle_feature.lbl_circle_multi_lang_meet') }}
      </button>
    </div>
  </div>
</template>

  <script>
import appUtils from '../../../utils/appUtils'
import { CIRCLE_TYPE } from '../../../utils/constants'
import CircleConferenceMembers from './CircleConferenceMembers.vue'
import CircleLiveMembers from './CircleLiveMembers.vue'

export default {
  name: 'CircleConferenceDetail',
  components: { CircleLiveMembers, CircleConferenceMembers },
  data () {
    return {
      user_100ms_role: 'guest',
      circle_member: {},
      circleData: {},
      isLoading: false,
      CIRCLE_TYPE
    }
  },
  computed: {
    isMember () {
      return !!this.circle_member && this.circle_member.status === 2
    },
    isAdmin () {
      return (
        (!!this.isMember && this.circle_member.role === 1) ||
        this.circleData?.created_by === this.$user?.id
      )
    },
    isMyHasAssignRole () {
      return ![null, '', 'viewer', 'guest', 'hls-viewer'].includes(
        this.circle_member?.one_hundred_ms_role
      )
    }
  },
  created () {
    this.handlePrepare()
  },

  methods: {
    handleBackToList () {
      this.$router.replace({
        name: 'CircleList'
      })
    },
    handleBackToDetail () {
      if (!this.$route.params.id) return

      this.$router.replace({
        name: 'CircleDetail',
        params: {
          id: this.$route.params.id
        }
      })
    },
    async handleGetCircleDetail () {
      if (!this.$route.params.id) return

      try {
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .showCircle(this.$route.params.id)

        this.circleData = response.data || {}

        return response.data
      } catch (error) {
        console.log(error)
        this.$toast.open({
          message: this.$t('circle_feature.lbl_permission_msg'),
          type: 'warning'
        })
      }
    },
    handleOpenEditModal () {
      this.$refs.ModalEditCircle.handleOpen(this.circleData)
    },
    async getCurrentMember () {
      if (!this.$user?.id) return

      const self = this
      let r = await this.$rf
        .getRequest('DoctorRequest')
        .getMemberByUserId(this.$route.params.id, this.$user.id, null, false)
        .then(async r => {
          if (
            self.circleData?.created_by === self.$user?.id &&
            !r.data?.one_hundred_ms_role
          ) {
            const role = {
              conference: 'presenter'
            }
            const res = await self.assignMember(
              r.data?.id,
              JSON.stringify(role)
            )
            self.circle_member = {
              ...r.data,
              one_hundred_ms_role:
                typeof res.data.one_hundred_ms_role === 'string' &&
                appUtils.isJSON(res.data.one_hundred_ms_role)
                  ? JSON.parse(res.data.one_hundred_ms_role || '{}')
                  : res.data.one_hundred_ms_role
            }
            return r
          }

          const role =
            typeof r.data?.one_hundred_ms_role === 'string' &&
            appUtils.isJSON(r.data?.one_hundred_ms_role)
              ? JSON.parse(r.data?.one_hundred_ms_role)
              : {
                conference: 'guest'
              }
          if (
            typeof r.data?.one_hundred_ms_role === 'string' &&
            !appUtils.isJSON(r.data?.one_hundred_ms_role)
          ) {
            await self.assignMember(r.data?.id, JSON.stringify(role))
          }

          self.circle_member = {
            ...r.data
          }

          return r
        })
        .catch(() => {
          let r = {
            data: null
          }
          return r
        })
      return r?.data
    },
    async handlePrepare () {
      this.isLoading = true

      try {
        await this.handleGetCircleDetail()
        const circleMemberResponse = await this.getCurrentMember()

        if (circleMemberResponse?.status === 0) return // NOT org member

        this.get100msRole()
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async get100msRole () {
      if (!this.circleData?.id || !this.$user?.id) return

      await this.$rf
        .getRequest('DoctorRequest')
        .checkMyRoleConferenceRoom({
          circle_id: this.circleData.id,
          user_id: this.$user.id
        })
        .then(res => {
          this.user_100ms_role =
            res.data?.one_hundred_ms_role ||
            JSON.stringify({
              conference: 'guest'
            })
        })
        .catch(e => {
          console.log(e)
          this.$toast.open({
            message: e,
            type: 'error'
          })
        })
    },

    async assignMember (circle_member_id, role_name) {
      try {
        const roleRaw =
          typeof role_name === 'string' && appUtils.isJSON(role_name)
            ? JSON.parse(role_name)
            : {
              conference: role_name
            }
        const member_role_ms =
          typeof this.circle_member?.one_hundred_ms_role === 'string' &&
          appUtils.isJSON(this.circle_member?.one_hundred_ms_role)
            ? JSON.parse(this.circle_member?.one_hundred_ms_role)
            : {}
        const role = {
          ...member_role_ms,
          ...roleRaw
        }
        const params = {
          circle_member_id: circle_member_id,
          role: JSON.stringify(role)
        }
        const res = await this.$rf
          .getRequest('DoctorRequest')
          .assignConferenceRole(params)
        return res.data
      } catch (error) {
        console.log(error)
      }
    },
    async goToMultiLangConference () {
      if (!this.circleData?.one_hundred_ms_conf_id) return

      const { id, one_hundred_ms_conf_id } = this.circleData
      await this.get100msRole().then(() => {
        let token = appUtils.getLocalToken()
        let domainOrigin = window.location?.origin
        let domain = ''

        if (domainOrigin?.includes('http://localhost')) {
          domain = 'http://localhost:8068'
        } else if (process.env.NODE_ENV === 'development' && !domainOrigin?.includes('http://localhost')) {
          domain = 'https://dev.live.hodo.app'
        } else {
          domain = 'https://live.hodo.app'
        }

        const roleObj = appUtils.isJSON(this.user_100ms_role)
          ? JSON.parse(this.user_100ms_role)
          : {
            conference: this.user_100ms_role
          }
        const role = roleObj['conference']
        const routeRaw = `${domain}/redirect.html?token=${token}&room_id=${one_hundred_ms_conf_id}&circle_id=${id}&type=conference&role=${role ||
          'guest'}&lang=${this.$lang}`

        console.log(routeRaw)

        let linkElement = document.createElement('a')
        const linkId = `conf_${one_hundred_ms_conf_id}`
        linkElement.id = linkId
        linkElement.href = routeRaw
        window.document.body.appendChild(linkElement)

        const linkEl = document.getElementById(linkId)
        linkEl.setAttribute('rel', 'noopener noreferrer')
        !appUtils.checkIsMobile() && linkEl.setAttribute('target', '_blank')
        linkEl.click()
        linkEl.remove()

        // window.open(routeRaw, "_blank");
      })
    },
    async createConferenceRoom () {
      try {
        const self = this

        if (!this.circleData?.id) {
          self.$toast.open({
            message: self.$t('lbl_circle_id_are_not_found'),
            type: 'error'
          })
          return
        }
        if (self.circleData?.one_hundred_ms_conf_id) {
          self.$toast.open({
            message: self.$t(
              'lbl_multilanguage_conference_room_has_already_existed__you_cannot_add_another_one'
            ),
            type: 'error'
          })
          return
        }
        if (!this.isAdmin) {
          self.$toast.open({
            message: self.$t(
              'lbl_you_do_not_have_permission_to_add_live_stream'
            ),
            type: 'error'
          })
          return
        }

        if (!self.circle_member) {
          await self.getCurrentMember()
        }

        await self.$rf
          .getRequest('DoctorRequest')
          .createConferenceRoom(this.circleData?.id)
          .then(async () => {
            self.$toast.open({
              message: self.$t(
                'lbl_create_multilanguage_conference_room_successfully'
              ),
              type: 'success'
            })

            const role = {
              conference: 'presenter',
              livestream: null
            }
            const res = await self.assignMember(
              self.circle_member?.id,
              JSON.stringify(role)
            )

            self.circle_member = {
              ...self.circle_member,
              one_hundred_ms_role: JSON.parse(
                res?.data?.one_hundred_ms_role || '{}'
              )
            }

            await self.handlePrepare()
          })
      } catch (error) {
        console.log(error)
        self.$toast.open({
          message: self.$t('lbl_failed_to_create_a_conference_room'),
          type: 'success'
        })
      }
    }
  }
}
</script>

  <style lang="scss" scoped>
.open-chat-att {
  padding: 16px;
  display: flex;
  align-items: center;
  background-color: #fafafa;
  width: 200px;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 16px;
  position: relative;
  transition: all 0.3s ease;

  &:hover {
    background-color: #eff4ff;
  }
}
</style>
